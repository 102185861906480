import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const IntroSection = () => {
  const data = useStaticQuery(graphql`{
  imgIntro: file(
    relativePath: {eq: "index(Landing)/introduction/introduction.jpeg"}
    sourceInstanceName: {eq: "images"}
  ) {
    childImageSharp {
      gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
    }
  }
}
`);
  return (
    <section id="intro">
      {/* <div className="intro-container"> */}
      <div className="image">
        <GatsbyImage
          image={data.imgIntro.childImageSharp.gatsbyImageData}
          alt="Virtual Labs Introduction" />
        <h2 id="text-explore" className="intro-subtitle">
          Explore
        </h2>
        <h2 id="text-experiment" className="intro-subtitle">
          Experiment
        </h2>
        <h2 id="text-discover" className="intro-subtitle">
          Discover
        </h2>
      </div>
    </section>
  );
};

export default IntroSection;
