import React from "react";

const SheetsGraph = (props) => {
  return (
    <iframe
      width="500"
      height="471"
      seamless
      frameBorder="0"
      scrolling="no"
      src={props.baseUrl + `pubchart?oid=${props.oid}&format=interactive`}
      className="iframe-placeholder"
    ></iframe>
  );
};

export default SheetsGraph;
