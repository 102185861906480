import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import Section from "./section-template";

const ActivitySection = () => {
  const images = useStaticQuery(graphql`{
  imgOutreach: file(
    relativePath: {eq: "index(Landing)/activities/outreach.png"}
    sourceInstanceName: {eq: "images"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, placeholder: TRACED_SVG, layout: CONSTRAINED)
    }
  }
  imgDevelopment: file(
    relativePath: {eq: "index(Landing)/activities/development.png"}
    sourceInstanceName: {eq: "images"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, placeholder: TRACED_SVG, layout: CONSTRAINED)
    }
  }
  imgResearch: file(
    relativePath: {eq: "index(Landing)/activities/research.jpg"}
    sourceInstanceName: {eq: "images"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, placeholder: TRACED_SVG, layout: CONSTRAINED)
    }
  }
}
`);

  const activitySectionData = {
    id: "activities",
    header: "Our Activities",
    content: `
        We work on cutting-edge open source technologies like Cloud Computing,
        Micro Services, DevOps, Python and Web technologies.
        We conduct workshops and setup nodal centers to partner with institutes
        of the Virtual Labs consortium for the adoption of Virtual Labs.
    `,
    cards: [
      {
        link: "/research",
        imgSrc: images.imgResearch.childImageSharp.gatsbyImageData,
        imgDesc: "Research Activity",
        desc: "Research",
      },
      {
        link: "/development",
        imgSrc: images.imgDevelopment.childImageSharp.gatsbyImageData,
        imgDesc: "Development Activity",
        desc: "Development",
      },
      {
        link: "/outreach",
        imgSrc: images.imgOutreach.childImageSharp.gatsbyImageData,
        imgDesc: "Outreach Activity",
        desc: "Outreach",
      },
    ],
  };

  return <Section sectionData={activitySectionData} />;
};

export default ActivitySection;
