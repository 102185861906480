import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import Section from "./section-template";

const FAQSection = () => {
  const images = useStaticQuery(graphql`{
  imgFAQLabs: file(
    relativePath: {eq: "index(Landing)/faq/virtualLabs.png"}
    sourceInstanceName: {eq: "images"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, placeholder: TRACED_SVG, layout: CONSTRAINED)
    }
  }
  imgFAQWorkshops: file(
    relativePath: {eq: "index(Landing)/faq/virtualLabsWorkshop.png"}
    sourceInstanceName: {eq: "images"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, placeholder: TRACED_SVG, layout: CONSTRAINED)
    }
  }
}
`);

  const faqSectionData = {
    id: "faq",
    header: "FAQs",
    cards: [
      {
        link: "/labs-faq",
        imgSrc: images.imgFAQLabs.childImageSharp.gatsbyImageData,
        imgDesc: "FAQ Virtual Labs",
        desc: "Virtual Labs",
      },
      {
        link: "/outreach#faq",
        imgSrc: images.imgFAQWorkshops.childImageSharp.gatsbyImageData,
        imgDesc: "FAQ Virtual Labs Workshops",
        desc: "Virtual Labs Workshops",
      },
    ],
  };

  return <Section sectionData={faqSectionData} />;
};

export default FAQSection;
