export const disciplines = {
  cs: {
    label: "Computer Science",
    labs: [
      { label: "Data Structures - 1", url: "http://ds1-iiith.vlabs.ac.in/" },
      { label: "Data Structures - 2", url: "http://ds2-iiith.vlabs.ac.in/" },
      { label: "Data Structures", url: "http://cse01-iiith.vlabs.ac.in/" },
      { label: "VLSI ", url: "http://cse14-iiith.vlabs.ac.in/" },
      {
        label: "Digital Logic Design",
        url: "http://cse15-iiith.vlabs.ac.in/",
      },
      { label: "Problem Solving", url: "http://ps-iiith.vlabs.ac.in/" },
      { label: "Pattern Recognition", url: "http://cse20-iiith.vlabs.ac.in/" },
      { label: "Image Processing", url: "http://cse19-iiith.vlabs.ac.in/" },
      {
        label: "Computer Organization",
        url: "http://cse11-iiith.vlabs.ac.in/",
      },
      { label: "Computer Graphics", url: "http://cse18-iiith.vlabs.ac.in/" },
      {
        label: "Speech Signal Processing",
        url: "http://ssp-iiith.vlabs.ac.in/",
      },
      {
        label: "Computer Programming",
        url: "http://cse02-iiith.vlabs.ac.in/",
      },
      {
        label: "Artificial Neural Networks",
        url: "http://cse22-iiith.vlabs.ac.in/",
      },
      {
        label: "Computational Linguistics",
        url: "http://cl-iiith.vlabs.ac.in/",
      },
      { label: "Natural Language Processing", url: "http://nlp.vlabs.ac.in/" },
      { label: "Cryptography", url: "http://cse29-iiith.vlabs.ac.in/" },
    ],
  },
  civil: {
    label: "Civil Engineering",
    labs: [
      {
        label: "Basic Structural Analysis",
        url: "http://bsa-iiith.vlabs.ac.in/",
      },
      { label: "Soil Mechanics Lab", url: "http://smfe-iiith.vlabs.ac.in/" },
      {
        label: "Hydraulics and Fluid Mechanics",
        url: "http://eerc03-iiith.vlabs.ac.in/",
      },
      { label: "Structural dynamics", url: "http://sd-iiith.vlabs.ac.in/" },
      {
        label: "Basic engineering Mechanics and Strength of Materials",
        url: "http://eerc01-iiith.vlabs.ac.in/",
      },
      {
        label: "Geotechnical Engineeering",
        url: "http://ge-iiith.vlabs.ac.in/",
      },
    ],
  },
  chem: {
    label: "Chemical Science",
    labs: [
      {
        label: "Quantum Chemistry",
        url: "http://ccnsb04-iiith.vlabs.ac.in/",
      },
      {
        label: "Circular Dichroism Spectroscopy",
        url: "http://cds-iiith.vlabs.ac.in/",
      },
      {
        label: "Physical Chemistry Virtual Lab",
        url: "http://ccnsb06-iiith.vlabs.ac.in/",
      },
      {
        label: "Molecular Absorption Spectroscopy",
        url: "http://mas-iiith.vlabs.ac.in/",
      },
      {
        label: "Colloid and Surface Chemistry",
        url: "http://csc-iiith.vlabs.ac.in/",
      },
      {
        label: "Molecular Florescence Spectroscopy",
        url: "http://mfs-iiith.vlabs.ac.in/",
      },
      {
        label: "Molecular Interactions",
        url: "http://mi-iiith.vlabs.ac.in/",
      },
    ],
  },
  others: {
    label: "Other Disciplines",
    labs: [
      {
        label: "Physical Science",
        url: "http://vlab.co.in/ba_labs_all.php?id=8",
      },
      {
        label: "Chemical Science",
        url: "http://vlab.co.in/ba_labs_all.php?id=9",
      },
      {
        label: "Civil Engineering",
        url: "http://vlab.co.in/ba_labs_all.php?id=7",
      },
      {
        label: "Chemical Engineering",
        url: "http://vlab.co.in/ba_labs_all.php?id=5",
      },
      {
        label: "Electrical Engineering",
        url: "http://vlab.co.in/ba_labs_all.php?id=3",
      },
      {
        label: "Mechanical Engineering",
        url: "http://vlab.co.in/ba_labs_all.php?id=4",
      },
      {
        label: "Electronics And Communications",
        url: "http://vlab.co.in/ba_labs_all.php?id=1",
      },
      {
        label: "Computer Science and Engineering",
        url: "http://vlab.co.in/ba_labs_all.php?id=2",
      },
      {
        label: "Biotechnology and Biomedical Engineering",
        url: "http://vlab.co.in/ba_labs_all.php?id=6",
      },
    ],
  },
};
