import React from "react";

import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import SectionBackground from "./background";

const Section = ({ sectionData, children }) => {
  return (
    <section className="section" id={sectionData.id}>
      <h2 className="section-header">{sectionData.header}</h2>
      {sectionData.content && (
        <p className="section-content">{sectionData.content}</p>
      )}
      {sectionData.cards && (
        <SectionBackground>
          <div className="background-image">
            <div className="columns is-mobile is-centered is-multiline">
              {sectionData.cards.map((c, idx) => (
                <div className="column is-narrow" key={idx}>
                  <Link to={c.link}>
                    <div className="card">
                      <div className="card-image">
                        <figure className="image">
                          <GatsbyImage className="indexCardImage" image={c.imgSrc} alt={c.imgDesc} />
                        </figure>
                      </div>
                      <div className="card-content">
                        <center>
                          <div className="content">{c.desc}</div>
                        </center>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </SectionBackground>
      )}
      { children }
    </section>
  );
};

export default Section;
