import React from "react";
import "../scss/charts.scss";
import SheetsGraph from "./sheets-graph";
import SectionBackground from "./main/background";
import { Link } from "gatsby";

const ChartsSection = (props) => {
  return (
    <section className="section">
      <h2 className="section-header">{props.content.title}</h2>
      <p className="section-content">{props.content.description}. </p>
      <SectionBackground>
        <Link to="/analytics">
          <div className="background-image">
            <div className="columns is-mobile is-centered is-multiline">
              {Object.keys(props.oids).map((elem, idx) => (
                <div className="column is-narrow" key={idx}>
                  <div className="card">
                    <SheetsGraph
                      baseUrl={props.baseUrl}
                      oid={props.oids[elem]}
                      key={props.oids[elem].uniqueId}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Link>
      </SectionBackground>
    </section>
  );
};

export default ChartsSection;
