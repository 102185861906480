import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";

const SectionBackground = ({ className, children }) => {
  const images = useStaticQuery(graphql`{
  imgBackground: file(
    relativePath: {eq: "index(Landing)/background.jpg"}
    sourceInstanceName: {eq: "images"}
  ) {
    childImageSharp {
      gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
    }
  }
}
`);

  const image = getImage(images.imgBackground);
  const bgImage = convertToBgImage(image);

  return (
    <BackgroundImage
      Tag="section-background"
      className={className}
      // fluid={images.imgBackground.childImageSharp.gatsbyImageData}
      {...bgImage}
      backgroundColor={`#040e18`}
    >
      {children}
    </BackgroundImage>
  );
};

export default SectionBackground;
