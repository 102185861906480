import React, { useEffect, useState } from "react";
import csvParser from "../utils/csv-parser";
import ChartsSection from "./charts-section";

const Charts = () => {
  const [arr, setArr] = useState([]);
  const baseUrl =
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vSAvfWhvZ6Jn0hoPgxO_HiHwvnGX9ItyeMFOpmtzNfa7xvkKWhkdYNIaF7TboAAIoaFGIwk7Ck9UQjA/";
  const oids_table_id = 233771544;
  const sections_content = [
    {
      title: "Views and Users Trend",
      description:
        "Virtual Labs has seen a steady increase in both the number of users and the number of pageviews with an inflection point at the start of August 2020. During the Corona pandemic, the Virtual Labs has been of immense assistance to the home-bound teaching community",
    },
    {
      title: "Top Labs and Disciplines",
      description:
        "Electronics and Communications Engineering has been the most popular discipline with Digital Electronics Lab of IIT Roorkee being the most popular lab in the Virtual Labs ecosystem. Civil Engineering, Electrical Engineering and Computer Science complete the quad of most popular disciplines",
    },
  ];

  const get_section_charts = (section_id) => {
    const charts_ids = {};
    arr.forEach((elem) => {
      if (elem.Section == section_id) {
        charts_ids[elem.Graph] = elem.Id;
      }
    });
    return charts_ids;
  };

  useEffect(() => {
    fetch(baseUrl + `pub?gid=${oids_table_id}&single=true&output=csv`)
      .then((response) => response.text())
      .then((resultData) => {
        setArr(csvParser(resultData));
      });
  }, []);

  return (
    <div className="columns">
      <ChartsSection className="column"
        content={sections_content[0]}
        baseUrl={baseUrl}
        oids={get_section_charts(1)}
      />
      <ChartsSection className = "column"
        content={sections_content[1]}
        baseUrl={baseUrl}
        oids={get_section_charts(2)}
      />
    </div>
  );
};

export default Charts;


