import React from "react";
import Layout from "../layout/layout";
import LabSection from "../components/main/lab-section";
import ActivitySection from "../components/main/activity-section";
import ServiceSection from "../components/main/service-section";
import IntroSection from "../components/main/intro-section";
import AboutSection from "../components/main/about-section";
import FAQSection from "../components/main/faq-section";
// import TeamSection from "../components/main/team-section"
import Charts from "../components/charts";

import "../scss/pages/index.scss";

const Landing = () => {

  return (
    <Layout>
      <IntroSection />
      <Charts/>
      <AboutSection />
      <LabSection />
      <ActivitySection />
      <ServiceSection />
      {/* <TeamSection/> */}
      <FAQSection />
    </Layout>
  );
};

export default Landing;
