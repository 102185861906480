import React, { useState } from "react";

import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import SectionBackground from "./background";
import { disciplines } from "../../data/disciplines";

const LabSection = () => {
  const images = useStaticQuery(graphql`{
  imgCivil: file(
    relativePath: {eq: "index(Landing)/labs/civilEngineering.png"}
    sourceInstanceName: {eq: "images"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, placeholder: TRACED_SVG, layout: CONSTRAINED)
    }
  }
  imgComputer: file(
    relativePath: {eq: "index(Landing)/labs/computerScience.png"}
    sourceInstanceName: {eq: "images"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, placeholder: TRACED_SVG, layout: CONSTRAINED)
    }
  }
  imgChemical: file(
    relativePath: {eq: "index(Landing)/labs/chemicalScience.png"}
    sourceInstanceName: {eq: "images"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, placeholder: TRACED_SVG, layout: CONSTRAINED)
    }
  }
  imgOtherDiscipline: file(
    relativePath: {eq: "index(Landing)/labs/otherDisciplines.png"}
    sourceInstanceName: {eq: "images"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, placeholder: TRACED_SVG, layout: CONSTRAINED)
    }
  }
}
`);

  const cards = [
    {
      id: "cs",
      imgSrc: images.imgComputer.childImageSharp.gatsbyImageData,
      imgDesc: `Discipline ${disciplines["cs"].label}`,
      desc: disciplines["cs"].label,
    },
    {
      id: "civil",
      imgSrc: images.imgCivil.childImageSharp.gatsbyImageData,
      imgDesc: `Discipline ${disciplines["civil"].label}`,
      desc: disciplines["civil"].label,
    },
    {
      id: "chem",
      imgSrc: images.imgChemical.childImageSharp.gatsbyImageData,
      imgDesc: `Discipline ${disciplines["chem"].label}`,
      desc: disciplines["chem"].label,
    },
    {
      id: "others",
      imgSrc: images.imgOtherDiscipline.childImageSharp.gatsbyImageData,
      imgDesc: `Discipline ${disciplines["others"].label}`,
      desc: disciplines["others"].label,
    },
  ];

  // const cards2 = [
  //   [
  //     {
  //     id: "cs",
  //     imgSrc: images.imgComputer.childImageSharp.gatsbyImageData,
  //     imgDesc: `Discipline ${disciplines["cs"].label}`,
  //     desc: disciplines["cs"].label,
  //     },
  //     {
  //       id: "cs",
  //       imgSrc: images.imgComputer.childImageSharp.gatsbyImageData,
  //       imgDesc: `Discipline ${disciplines["cs"].label}`,
  //       desc: disciplines["cs"].label,
  //     },
  //     {
  //       id: "cs",
  //       imgSrc: images.imgComputer.childImageSharp.gatsbyImageData,
  //       imgDesc: `Discipline ${disciplines["cs"].label}`,
  //       desc: disciplines["cs"].label,
  //     },
  //     {
  //       id: "cs",
  //       imgSrc: images.imgComputer.childImageSharp.gatsbyImageData,
  //       imgDesc: `Discipline ${disciplines["cs"].label}`,
  //       desc: disciplines["cs"].label,
  //     },
  //     {
  //       id: "cs",
  //       imgSrc: images.imgComputer.childImageSharp.gatsbyImageData,
  //       imgDesc: `Discipline ${disciplines["cs"].label}`,
  //       desc: disciplines["cs"].label,
  //     }
  //   ],
  //   [
  //     {
  //     id: "civil",
  //     imgSrc: images.imgCivil.childImageSharp.gatsbyImageData,
  //     imgDesc: `Discipline ${disciplines["civil"].label}`,
  //     desc: disciplines["civil"].label,
  //     }
  //   ],
  //   [
  //     {
  //     id: "chem",
  //     imgSrc: images.imgChemical.childImageSharp.gatsbyImageData,
  //     imgDesc: `Discipline ${disciplines["chem"].label}`,
  //     desc: disciplines["chem"].label,
  //     }
  //   ],
  //   [
  //     {
  //     id: "others",
  //     imgSrc: images.imgOtherDiscipline.childImageSharp.gatsbyImageData,
  //     imgDesc: `Discipline ${disciplines["others"].label}`,
  //     desc: disciplines["others"].label,
  //     }
  //   ],
  // ];


  const [modalOpted, setModalOpted] = useState("none");
  // var curr = 0;

  return (
    <section className="section" id="labs">
      <h2 className="section-header" id="labsHeader">
        Our Labs
      </h2>
      <p className="section-content">
        Explore our labs/experiments across various engineering disciplines.{" "}
      </p>
      <SectionBackground>
        <div className="background-image">
          <div className="columns is-mobile is-centered is-multiline">
            {cards.map((c, idx) => (
              <div className="column is-narrow" key={idx}>
                <div className="card">
                  <div className="card-image">
                    <figure
                      className="image"
                      onClick={() => setModalOpted(c.id)}
                      // onClick={() => modalOpted == c.id ? setModalOpted("none") :setModalOpted(c.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <GatsbyImage className="indexCardImage" image={c.imgSrc} alt={c.imgDesc} />
                    </figure>
                  </div>
                  <div className="card-content">
                    <center>
                      <div className="content">{c.desc}</div>
                    </center>
                  </div>
                </div>
              </div>
              

            ))}
          </div>

          {/* <div className = {disciplines[modalOpted] ? "columns is-centered is-multiline" : "is-hidden"}>
            {modalOpted === "cs" ? curr = 0 : curr = curr}
            {modalOpted === "civil" ? curr = 1 : curr = curr}
            {modalOpted === "chem" ? curr = 2 : curr = curr}
            {modalOpted === "others" ? curr = 3 : curr = curr}
            {cards2[curr].map((c, idx) => (
                <div className="column is-narrow" key={idx}>
                  <div className="card ml-0">
                    <div className="card-image">
                      <figure
                        className="image"
                        // onClick={() => setModalOpted(c.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <GatsbyImage className="indexCardImage" image={c.imgSrc} alt={c.imgDesc} />
                      </figure>
                    </div>
                    <div className="card-content">
                      <center>
                        <div className="content">{c.desc}</div>
                      </center>
                    </div>
                  </div>
                </div>
              ))}
          </div> */}
        </div>
      </SectionBackground>
      {/* <div className =  */}
      <div className={disciplines[modalOpted] ? "modal is-active" : "modal"}>
        {/* <div className="modal-background"></div> */}
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">
              {modalOpted === "others" ? "Labs" : "IIITH Labs"}
              {disciplines[modalOpted]
                ? ` for ${disciplines[modalOpted].label}`
                : ""}
            </p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => setModalOpted("none")}
            ></button>
          </header>
          <section className="modal-card-body">
            {disciplines[modalOpted] ? (
              disciplines[modalOpted].labs.map((l) => (
                <li>
                  <a target="_blank" href={l.url}>
                    {l.label}
                  </a>
                </li>
              ))
            ) : (
              <p>No labs found</p>
            )}
          </section>
          <footer className="modal-card-foot"></footer>
        </div>
      </div>
    </section>
  );
};

export default LabSection;
