import React, { useState } from "react";

// import { useStaticQuery, graphql } from "gatsby";
// import Carousel from "../../components/carousel";
// import Img from "gatsby-image";
import Section from "./section-template";

const AboutSection = () => {
  // const data = useStaticQuery(graphql`
  //   {
  //     allFile(filter: { dir: { regex: "*/about/i" } }) {
  //       nodes {
  //         name
  //         childImageSharp {
  //           fluid(maxHeight: 900, maxWidth: 1600) {
  //             srcWebp
  //             originalImg
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);

  // let idx = 0;
  // const aboutImgSources = data.allFile.nodes.map((n) => ({
  //   idx: ++idx,
  //   name: n.name,
  //   webp: n.childImageSharp.fluid.srcWebp,
  //   orig: n.childImageSharp.fluid.originalImg,
  // }));

  const aboutSectionData = {
    id: "about",
    header: "About Virtual Labs",
    content: `
          Virtual Labs, a mission mode MHRD project under NMEICT, provides free,
          laboratory learning experience to students through remote
          experimentation. Free Workshops, are conducted and nodal centers are
          setup to partner with institutes of the Virtual Labs consortium for
          the adoption of Virtual Labs. To keep in pace with growing needs of
          students, experiments across domains are conceptualized, aligned and
          built using cutting-edge open source technologies. State of the art
          digital classroom is available for streaming video lectures to
          complement Virtual Lab experiments.
    `,
  };

  return (
    <Section sectionData={aboutSectionData}>
      {/* <Carousel sources={aboutImgSources} /> */}
    </Section>
  );
};

export default AboutSection;
