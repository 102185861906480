import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import Section from "./section-template";

const ServiceSection = () => {
  const images = useStaticQuery(graphql`{
  imgAnalytics: file(
    relativePath: {eq: "index(Landing)/services/analytics.png"}
    sourceInstanceName: {eq: "images"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, placeholder: TRACED_SVG, layout: CONSTRAINED)
    }
  }
  imgHosting: file(
    relativePath: {eq: "index(Landing)/services/hosting.jpg"}
    sourceInstanceName: {eq: "images"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, placeholder: TRACED_SVG, layout: CONSTRAINED)
    }
  }
  imgVirtualbox: file(
    relativePath: {eq: "index(Landing)/services/virtualbox.png"}
    sourceInstanceName: {eq: "images"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, placeholder: TRACED_SVG, layout: CONSTRAINED)
    }
  }
}
`);

  const serviceSectionData = {
    id: "services",
    header: "Our Services",
    content: `
        We provide centralized services like analytics for all Labs hosted by
        us. For participants who are not able to access the Virtual Labs over
        the web, there's a Virtual Box based local solution available.
    `,
    cards: [
      {
        link: "/analytics",
        imgSrc: images.imgAnalytics.childImageSharp.gatsbyImageData,
        imgDesc: "Analytics Service",
        desc: "Analytics",
      },
      {
        link: "/hosting",
        imgSrc: images.imgHosting.childImageSharp.gatsbyImageData,
        imgDesc: "Hosting Service",
        desc: "Hosting",
      },
      {
        link: "/development#virtual-box",
        imgSrc: images.imgVirtualbox.childImageSharp.gatsbyImageData,
        imgDesc: "Virtualbox Service",
        desc: "VirtualBox for Virtual Lab",
      },
    ],
  };

  return <Section sectionData={serviceSectionData} />;
};

export default ServiceSection;
